
import { defineComponent, ref, onMounted, computed, getCurrentInstance } from "vue";
import router from "@/router";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import CustomerImportModal from "@/components/modals/forms/CustomerImportModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import { useStore } from "vuex";
import CustomerService from "@/core/services/car/CustomerService";
import UserService from "@/core/services/UserService";
import MasterService from "@/core/services/car/MasterService";
import Multiselect from "@vueform/multiselect";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import mainSocket from "./../../../main-socket";
import { UserTypesMixin } from "@/core/plugins/vcMixins";
import { hideModal } from "@/core/helpers/dom";
import { ElNotification } from "element-plus";
import { computeShrinkWidth } from "@fullcalendar/common";
import { Mutations } from "@/store/enums/StoreEnums";
import { customFunction, roles } from "@/store/stateless/store";
import moment from "moment";

interface ICustomer {
  id: number;
  name: string;
  email: string;
  company: string;
  payment: {
    icon: string;
    ccnumber: string;
    label: string;
  };
  date: string;
}
interface CustomerBodyParam {
  per_page: string;
  task_due_dates: string;
  customer_status_id: any;
  agent: any;
  lead_status_id: number;
  keyword: string;
  start_date: string;
  end_date: string;
  is_hot_lead?: boolean;
  is_hot_renewal_lead?: boolean;
}
interface TransferLeadsParam {
  customer_ids: [];
  agent_id: string;
  notes: string;
}
interface ITaskAgentList {
  placeholder: any;
  value: number | null;
  options: any;
  searchable: boolean;
  clearOnSelect: boolean;
}
export default defineComponent({
  name: "customers-listsssssss",
  components: {
    // Datatable,
    ExportCustomerModal,
    AddCustomerModal,
    CustomerImportModal,
    Multiselect
    // TippyComponent,
  },
  data() {
    return {
      shortcuts: [
        {
          text: "Today",
          value: (() => {
            const end = new Date();
            const start = new Date();
            return [start, end];
          })()
        },
        {
          text: "Yesterday",
          value: (() => {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          })()
        },
        {
          text: "Last 7 Days",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last 30 Days",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "This Month",
          value: (() => {
            const end = new Date();
            const start = new Date(end.getFullYear(), end.getMonth(), 1);
            return [start, end];
          })()
        },
        {
          text: "Last Month",
          value: (() => {
            const end = new Date();
            end.setDate(0);
            const start = new Date(end.getFullYear(), end.getMonth(), 1);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ]
    };
  },
  setup() {
    // let customers_obj = ref([]);
    const store = useStore();
    const router = useRouter();
    let bodyParameters = ref<CustomerBodyParam>({
      per_page: "50",
      task_due_dates: "all",
      customer_status_id: -1,
      agent: "-1",
      lead_status_id: -1,
      keyword: "",
      start_date: "",
      end_date: "",
      is_hot_lead: false,
      is_hot_renewal_lead: false
    });
    const isClick = ref<boolean>(false);
    //  bodyParameters = ref();

    // {} as CustomerBodyParam;

    let transferLeadsParam = {} as TransferLeadsParam;
    const checkedCustomers = ref([]);
    let enableSearchDom = ref(true);
    let isSelectAll = ref(false);
    let selectedAgent = ref({ id: -1, name: "Mine" });
    // let isUserAdmin = ref(false);
    let sales_agent_id: any;
    let userStr: any;
    let userObj = ref<any>({});
    let daterange = ref<any>();
    const customers = ref<Array<ICustomer>>([]);
    const initCustomers = ref<Array<ICustomer>>([]);
    let showLeadsFilterDom = ref<boolean>(false);
    let customer_obj = ref<Array<any>>([
      {
        id: 1,
        name: "borer",
        email: "borer.aditya@example.org",
        phone_number: 1 - 838 - 564 - 7179,
        agent: 2,
        last_visited_at: "1990-11-14 11:58:45",
        current_visited_at: "1990-11-14 11:58:45",
        created_at: "2021-09-29T06:27:54.000000Z",
        updated_at: "2021-09-30T11:58:00.000000Z",
        task_count: 0,
        hot_lead: 0,
        agent_details: {
          id: 2,
          name: "ashwin"
        }
      }
    ]);
    let userTypeList = computed(() => {
      const types = store.getters.getUserTypeList
      types.unshift({
        label: 'Please Select User/Customer Type',
        value: -1
      })

      return types
    });
    const userType = ref({
      placeholder: "Please Select User/Customer Type",
      value: null,
      options: userTypeList,
      searchable: true
    });

    transferLeadsParam = {
      customer_ids: [],
      agent_id: "",
      notes: ""
    };


    const listingCustomerTypes = ref()

    const getCustomerData = (store, bodyParameters, reload = true) => {

      currentPage.value = 1;
      let enableableCountApiCalls = true;
      // enableableCountApiCalls = bodyParameters.agent == "all" ? false : true;
      // API Action to get customers list
      bodyParameters.keyword = "";
      search.value = "";
      bodyParameters.reload = reload // set value if page going to load
      CustomerService.getCustomerList(store, bodyParameters);
      if (enableableCountApiCalls) {
        // CustomerService.getCustomerStatusCount(store, bodyParameters);
        getCounts(store, bodyParameters)
      }
    };

    const getCounts = (store, bodyParameters) => {
      bodyParameters.reload = false
      CustomerService.getCustomerLeadStatusFilterCount(store, bodyParameters);
      CustomerService.getDueDateTaskCount(store, bodyParameters);
      CustomerService.getFilteredDueDateTaskCount(store, bodyParameters);
    }


    const getCustomerLeadStatusFilterCount = (store, bodyParameters) => {
      CustomerService.getCustomerLeadStatusFilterCount(store, bodyParameters);
    };

    const getDueDateTaskCount = (store, bodyParameters) => {
      CustomerService.getDueDateTaskCount(store, bodyParameters);
    };

    const getFilteredDueDateTaskCount = (store, bodyParameters) => {
      CustomerService.getFilteredDueDateTaskCount(store, bodyParameters);
    };

    const getCustomerLeadStatusCount = (store, bodyParameters) => {
      // For Toolbar on leads and tasks
      bodyParameters.value.start_date = '';
      bodyParameters.value.end_date = '';
      CustomerService.getCustomerLeadStatusCount(store, bodyParameters.value);
    };
    onMounted(() => {
      if(!roles.leadsTasks.includes(roles.userId())) window.location.href = '/#/404'


      MenuComponent.reinitialization();
      initCustomers.value.splice(0, customers.value.length, ...customers.value);
      setCurrentPageBreadcrumbs("Leads & Tasks", []);
      userStr = LocalStorageServices.getUser();
      userObj.value = JSON.parse(userStr);
      bodyParameters.value.agent = (userObj.value.role_id == 1 || userObj.value.role_id == 14 || userObj.value.role_id == 15) ? "all" : "-1";

      getCustomerLeadStatusCount(store, bodyParameters);

      
      //All customers for Admin & QCA
      getCustomerData(store, bodyParameters.value);
      console.log('bodyParameters.value',bodyParameters.value)
      // CustomerService.getCalendarDetails(store);
      CustomerService.getSkyeAgentList(store);
      // UserService.getDomSalesAgents();
      // UserService.getDomSalesAgentsManagerTeamLeader();
      MasterService.getUserTypes();
      // isUserAdmin.value = store.getters.getUserPermissions.is_admin;
      // console.log(isUserAdmin.value);

      const currentUserId = userObj.value.id;
      mainSocket
        .channel("channel-" + currentUserId)
        .listen("CarTaskCountSocketEvent", (response) => {
          if (response.task_count) {
            getDueDateTaskCount(store, bodyParameters.value);
            // getFilteredDueDateTaskCount(store, bodyParameters.value);
          }
        });
      mainSocket
        .channel("channel-" + currentUserId)
        .listen("CarLeadListingSocketEvent", (response) => {
          if (response.status) {
            getCustomerData(store, bodyParameters.value, false);
          }
        });

        mainSocket
        .channel("policy-channel")
        .listen("CarPolicyCountSocketEvent", (response) => {
          // console.log("policy-channel", response);
            getCounts(store, bodyParameters.value);
        });

        const types = store.getters.getUserTypeList;


      types.push({
        label: 'Please Select User/Customer Type',
        value: -1
      })

      listingCustomerTypes.value = types
    });

    const isUserAdmin = computed(() => {
      const data = store.getters.getUserPermissions;
      return data.is_admin || data.is_manager || data.is_team_leader || userObj.value.role_id == 14 || userObj.value.role_id == 15;
    });
    // get default value for the select agent
    let salesAgentList = computed(() => {
      return store.getters.getDomSalesAgent;
    });
    const salesAgent = ref({
      placeholder: "Please Select",
      value: null,
      options: salesAgentList,
      searchable: true,
      clearOnSelect: true
    });

    // get default value for the agent dashboard
    let salesDashboardAgentList = computed(() => {
      return store.getters.getTeamMemberList;
    });
    const taskListsalesAgent = ref<ITaskAgentList>({
      placeholder: "Select agent view for",
      value: null,
      options: salesDashboardAgentList,
      searchable: true,
      clearOnSelect: true
    });

    const getPaginationUrl = (index) => {
      // let pagenationUrl = index.split("=");
      window.scrollTo({ top: 0, behavior: 'smooth' })
      isSelectAll.value = false;
      checkedCustomers.value = [];
      bodyParameters.value.keyword = "";
      bodyParameters.value.keyword = "";
      CustomerService.getCustomerList(
        store,
        bodyParameters.value,
        currentPage.value
      );
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.per_page = event.target.value;
      getCustomerData(store, bodyParameters.value);
    };
    const onCustomerTypeChange = () => {
      // bodyParameters.value.customer_status_id = event == null ? -1 : event;
      getCustomerData(store, bodyParameters.value);
    };
    const getCustomerListFor = (listFor) => {
      if (listFor == "all") {
        taskListsalesAgent.value.value = null;
      }
      if (listFor == "-1" || listFor == "all") {
        selectedAgent.value = { id: -1, name: "Mine" };
        userType.value.value = null;
      } else {
        selectedAgent.value = store.getters.getSalesAgentList.find((agent) => {
          return listFor === agent.id;
        });
      }

      enableSearchDom.value =
        listFor == "-1" || isUserAdmin.value ? true : false;
      bodyParameters.value.agent = listFor;
      bodyParameters.value.lead_status_id = -1;
      bodyParameters.value.customer_status_id = -1;
      bodyParameters.value.start_date = "";
      bodyParameters.value.end_date = "";
      daterange.value = "";
      search.value = "";
      getCustomerData(store, bodyParameters.value);
    };
    const getTasksForLeadStatus = (status) => {
      if (bodyParameters.value.lead_status_id == status) {
        bodyParameters.value.lead_status_id = -1;
      } else {
        bodyParameters.value.lead_status_id = status;
      }
      getCustomerData(store, bodyParameters.value);
    };
    const getTaskListPeriod = (listPeriod) => {
      if (listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        bodyParameters.value.start_date =
          startTS.getFullYear() +
          "-" +
          (startTS.getMonth() + 1) +
          "-" +
          startTS.getDate();
        bodyParameters.value.end_date =
          endTS.getFullYear() +
          "-" +
          (endTS.getMonth() + 1) +
          "-" +
          endTS.getDate();
      } else {
        bodyParameters.value.start_date = "";
        bodyParameters.value.end_date = "";
      }
      getCustomerData(store, bodyParameters.value);
    };
    const getTaskListFor = (due_date) => {
      bodyParameters.value.task_due_dates = due_date;
      activeLink.value = due_date;
      getCustomerData(store, bodyParameters.value);
    };
    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < customers.value.length; i++) {
        if (customers.value[i].id === id) {
          customers.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchCustomers = () => {
      bodyParameters.value.customer_status_id =
        userType.value.value == null ? -1 : userType.value.value;

      bodyParameters.value.keyword = search.value;
      activeLink.value = "All";
      CustomerService.getCustomerList(store, bodyParameters.value);
      //CustomerService.getCustomerStatusCount(store, bodyParameters.value);
      CustomerService.getCustomerLeadStatusFilterCount(store, bodyParameters.value);
      CustomerService.getDueDateTaskCount(store, bodyParameters.value);
      CustomerService.getFilteredDueDateTaskCount(store, bodyParameters.value);
    };
    const resetBodyParam = () => {
      daterange.value = "";
      enableSearchDom.value = true;
      search.value = "";
      userType.value.value = null;
      taskListsalesAgent.value.value =
        currentUser.value.role_id == 1 || currentUser.value.role_id == 13
          ? null
          : -1;
      selectedAgent.value = { id: -1, name: "Mine" };
      activeLink.value = "All";
      bodyParameters.value.per_page = "50";
      bodyParameters.value.task_due_dates = "all";
      bodyParameters.value.customer_status_id = -1;
      bodyParameters.value.agent = [1,14,15].includes(userObj.value.role_id) ? "all" : "-1";
      bodyParameters.value.lead_status_id = -1;
      bodyParameters.value.keyword = "";
      bodyParameters.value.start_date = "";
      bodyParameters.value.end_date = "";
      bodyParameters.value.is_hot_lead = false;
      bodyParameters.value.is_hot_renewal_lead = false;

      getCustomerData(store, bodyParameters.value);
    };
    const notes = ref<string>("");
    const transferPolicyModalRef1 = ref<null | HTMLElement>(null);
    const isRequired = ref<boolean>(false);
    const transferLeads = () => {
      isRequired.value = false;

      if (!salesAgent.value.value) {
        isRequired.value = true;
      } else {
        isClick.value = true;
        sales_agent_id;
        checkedCustomers.value.forEach(function (customer) {
          transferLeadsParam.customer_ids.push(customer);
        });
        transferLeadsParam.agent_id = sales_agent_id;
        transferLeadsParam.notes = notes.value;
        notes.value = "";
     
        CustomerService.transferLeads(store, transferLeadsParam)
          .then(() => {
            isClick.value = false;
            getCustomerData(store, bodyParameters.value);
            checkedCustomers.value.length = 0;
            salesAgent.value.value = null;
            transferLeadsParam.customer_ids = [];
            transferLeadsParam.agent_id = "";
            transferLeadsParam.notes = "";
            isSelectAll.value = false;
           
            hideModal(transferPolicyModalRef1.value);
          })
          .catch((error) => {
            isClick.value = false;
            checkedCustomers.value.length = 0;
            salesAgent.value.value = null;
            transferLeadsParam.customer_ids = [];
            transferLeadsParam.agent_id = "";
            transferLeadsParam.notes = "";
            isSelectAll.value = false;

            // hideModal(transferPolicyModalRef1.value);
          });
      }
    };
    const onSalesAgentChange = (event) => {
      sales_agent_id = event;
    };
    const getSkyeAgentList = () => {
      // console.log("get sales agents list API commented");
      let isTranfer = bodyParameters.value.agent == "-1" ? false : true;
      UserService.getDomSalesAgents({
        type: "Car",
        isArchived: true,
        isActive: true,
        isTransfer: isTranfer
      });
      toFirst()
      // CustomerService.getSkyeAgentList(store);
    };
    const gotoCustomerDetails = (customer_id) => {
      router.push({
        name: "customer-details",
        params: { customerId: customer_id }
      });
    };
    const exportCustomers = () => {
      //
      CustomerService.exportCustomer(store, bodyParameters);
    };
    const showDom = computed(() => {
      return store.getters.getDomStatus;
    });
    const userPermission = computed(() => {
      return store.getters.getUserPermissions;
    });

    const user = computed(() => {
      const current = window.localStorage.getItem("user");
      return JSON.parse(current || "");
    });
    
    console.log(user);
    if (
      userPermission.value.is_manager ||
      user?.value.role_id == 9 ||
      user?.value.role_id == 13
    ) {
      
      taskListsalesAgent.value.value = -1;
      selectedAgent.value = { id: -1, name: "Mine" };
    }
    const displayAgentSelection = () => {
      taskListsalesAgent.value.value = null;
    };
    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const onChangeHotLead = () => {
      bodyParameters.value.is_hot_renewal_lead = false;
      bodyParameters.value.is_hot_lead = !bodyParameters.value.is_hot_lead;
      getCustomerData(store, bodyParameters.value);
    };

    const onChangeHotRenewalLead = () => {
      bodyParameters.value.is_hot_lead = false;
      bodyParameters.value.is_hot_renewal_lead =
        !bodyParameters.value.is_hot_renewal_lead;
      getCustomerData(store, bodyParameters.value);
    };

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const paginatioObject = computed(() => {
      return store.getters.getPaginationObject;
    });

    const currentPage = ref(1);

    const activeLink = ref("All");

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
      store.commit(Mutations.SET_LEAD_LOADING, true);
    });

    const loading = computed(() => {
      return store.getters.getLeadLoading;
    });

    const clearShift = () => {
      getCustomerListFor("all");
    };


    const showLeadsFilter = () => {
      showLeadsFilterDom.value = !showLeadsFilterDom.value;
      if (bodyParameters.value.lead_status_id != -1) {
        bodyParameters.value.lead_status_id = -1;
        CustomerService.getCustomerList(store, bodyParameters.value);
        CustomerService.getCustomerStatusCount(store, bodyParameters.value);
        
        CustomerService.getDueDateTaskCount(store, bodyParameters.value);
        CustomerService.getFilteredDueDateTaskCount(
          store,
          bodyParameters.value
        );
      }
      else
      {
        if(showLeadsFilterDom.value)
        {
          CustomerService.getCustomerLeadStatusFilterCount(store, bodyParameters.value);

        }
      }
    };

    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    const dateFormat = (e) => {
      if(e) return moment(e, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")

      return null
    }

    const loggedInUser = computed(() => {
      const current = window.localStorage.getItem('user');
      return JSON.parse(current || '');
    });

    const inst = getCurrentInstance()

    const toFirst = () => {
      setTimeout(() => {
        const agentRef:any = inst?.refs?.agentRef
        agentRef.focusSearch()
      }, 500);
    }

    const resetLeadTransferModal = () => {
      isClick.value = false;
      salesAgent.value.value = null;
      transferLeadsParam.agent_id = "";
      transferLeadsParam.notes = "";
      notes.value = "";
    }


    let getCustomersList = computed(() => {
      return store.getters.getCustomers;

      
      // return customerIds;
    });
    

    const onSelectAllCustomer = (event) => {
     
      console.log('event',event.target.value)
      console.log(isSelectAll.value);
      if(isSelectAll.value)
      {
        checkedCustomers.value = getCustomersList.value.map(function (customer) {
          return customer.id;
        });
        
      }
      else
      {
        checkedCustomers.value = [];
      }
    }

    const role_id = computed(() => {
        const lSUser = window.localStorage.getItem('user')
        if(lSUser) {
            const user = JSON.parse(lSUser)
            return user.role_id
        }

        return 0
    })

    return {
      role_id,
      isSelectAll,
      getCustomersList,
      onSelectAllCustomer,
      resetLeadTransferModal,
      toFirst,
      loggedInUser,
      roles,
      dateFormat,
      listingCustomerTypes,
      userTypeList,
      getTextWidth,
      clearShift,
      customFunction,
      isClick,
      loading,
      ElNotification,
      activeLink,
      isUserAdmin,
      currentPage,
      paginatioObject,
      isRequired,
      transferPolicyModalRef1,
      currentUser,
      UserTypesMixin,
      showDom,
      customers,
      deleteCustomer,
      search,
      searchCustomers,
      checkedCustomers,
      deleteFewCustomers,
      customer_obj,
      onPerPageChange,
      onCustomerTypeChange,
      getPaginationUrl,
      getCustomerListFor,
      bodyParameters,
      getTasksForLeadStatus,
      getTaskListPeriod,
      getTaskListFor,
      resetBodyParam,
      getSkyeAgentList,
      transferLeads,
      onSalesAgentChange,
      notes,
      exportCustomers,
      enableSearchDom,
      selectedAgent,
      userPermission,
      userType,
      daterange,
      salesAgent,
      gotoCustomerDetails,
      userObj,
      taskListsalesAgent,
      displayAgentSelection,
      onChangeHotLead,
      onChangeHotRenewalLead,
      getFilteredDueDateTaskCount,
      showLeadsFilter,
      showLeadsFilterDom,
      user,
      
    };
  }
});
